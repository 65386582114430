














import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VideoGridNew from '@/components/VideoGridNew.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import Loader from '@/components/Loader.vue';

@Component({components: {Loader, VideoGridNew}})
export default class ModelClips extends Vue {
  @Prop() readonly model!: ModelDataInterface;
  @Prop({default: false}) readonly isSubscribed!: boolean;

  vids: AffiliateContentResponse[] = [];
  isRequestSend = false;
  totalVids = 0;
  params = {
    page: 1,
    take: 9,
    performerId: '',
  };

  mounted() {
    if (!this.isLogin) {
      this.params.take = 2;
    }

    if (this.model) {
      this.params.performerId = this.model._id;
      const storage = JSON.parse(localStorage.getItem('fan') as string);
      if (
        storage &&
        storage[this.$route.name as string] &&
        this.params.performerId === storage[this.$route.name as string].performerId
      ) {
        this.totalVids = storage[this.$route.name as string].count;
        this.vids.push(...storage[this.$route.name as string].items);
        this.getVids(true, true);
      } else {
        this.getVids(true);
      }
    }
    // window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    // const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (!this.isRequestSend && this.totalVids > this.vids.length) {
      this.getVids();
    }
  }

  @Watch('model')
  onModelChange(model: ModelDataInterface, oldModel: ModelDataInterface) {
    if (!this.isLogin) {
      this.params.take = 2;
    }

    if (model) {
      this.params.performerId = model._id;
      this.refreshVids();
    }
  }

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get isPerformer(): boolean {
    return vxm.user.data.role === 'performer';
  }

  get isMy(): boolean {
    if (this.isPerformer) {
      return this.model._id ? this.model._id === vxm.user.data._id : true;
    }
    return false;
  }

  get paymentSuccessCount() {
    return this.$store.state.paymentSuccessCount;
  }

  @Watch('paymentSuccessCount') onPaymentMessage(isFirst = false, backgroundUpdate = false) {
    const take = (this.params.page as number) * (this.params.take as number);
    if (!this.isPerformer || this.isMy) {
      this.isRequestSend = !backgroundUpdate;

      const api =
        vxm.user.role === 'performer' && this.model._id === vxm.user.data._id
          ? vxm.model.getMyAffiliateContent
          : vxm.fan.getAffiliateContentByPerformer;
      api({...this.params, page: 1, take: take})
        .then((res) => {
          this.totalVids = res.data.count;
          if (isFirst) {
            this.vids = res.data.items;
            this.updLocalStorage(res.data);
          } else {
            this.vids.push(...res.data.items);
          }
          (this.params.page as number)++;
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }

  getVids(isFirst = false, backgroundUpdate = false) {
    if (!this.isPerformer || this.isMy) {
      this.isRequestSend = !backgroundUpdate;

      const api =
        vxm.user.role === 'performer' && this.model._id === vxm.user.data._id
          ? vxm.model.getMyAffiliateContent
          : vxm.fan.getAffiliateContentByPerformer;
      api(this.params)
        .then((res) => {
          this.totalVids = res.data.count;
          if (isFirst) {
            this.vids = res.data.items;
            this.updLocalStorage(res.data);
          } else {
            this.vids.push(...res.data.items);
          }
          (this.params.page as number)++;
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }

  updLocalStorage(data) {
    let storage = JSON.parse(localStorage.getItem('fan') as string);
    if (!storage) {
      storage = {};
      storage[this.$route.name as string] = {items: [], count: 0, performerId: ''};
    }
    storage[this.$route.name as string] = {
      performerId: this.params.performerId,
      items: data.items,
      count: data.count,
    };
    localStorage.setItem('fan', JSON.stringify(storage));
  }

  refreshVids() {
    this.params.page = 1;
    this.vids = [];
    const storage = JSON.parse(localStorage.getItem('fan') as string);
    if (
      storage &&
      storage[this.$route.name as string] &&
      this.params.performerId === storage[this.$route.name as string].performerId
    ) {
      this.totalVids = storage[this.$route.name as string].count;
      this.vids.push(...storage[this.$route.name as string].items);
      this.getVids(true, true);
    } else {
      this.getVids(true);
    }
  }
}
