






































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import Loader from '@/components/Loader.vue';
import {PhotoInterface} from '@/types/photoInterface';
import {VideoInterface} from '@/types/videoInterface';
import PostCardNew from '@/components/PostCardNew.vue';
import DebounceMixin from '@/mixins/DebounceMixin';
import LoginOrRegisterModal from '@/components/modals/LoginOrRegisterModal.vue';

@Component({components: {Loader, PostCardNew, LoginOrRegisterModal}})
export default class ModelAll extends Mixins(DebounceMixin) {
  viewType = 'grid';
  media: Array<AffiliateContentResponse | PhotoInterface | VideoInterface> = [];
  isRequestSend = false;
  totalMedia = 0;
  params = {
    page: 1,
    take: 16,
    performerId: '',
    sort: 'newest',
  };
  debouncedSearch = this.debounce(this.changeSearch, 700);
  sortOptions = [
    {
      value: 'newest',
      name: 'Newest',
    },
    {
      value: 'oldest',
      name: 'Oldest',
    },
  ];

  @Prop() readonly model!: ModelDataInterface;
  @Prop({default: false}) readonly isSubscribed!: boolean;
  randomPromoIndex = this.randomInt(3, 12);
  showLoginModal = false;

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  mounted() {
    if (!this.isLogin) {
      this.params.take = 2;
    }
    if (this.model) {
      this.params.performerId = this.model._id;
      const storage = JSON.parse(localStorage.getItem('fan') as string);
      if (
        storage &&
        storage[this.$route.name as string] &&
        this.params.performerId === storage[this.$route.name as string].performerId
      ) {
        this.totalMedia = storage[this.$route.name as string].count;
        this.media.push(...storage[this.$route.name as string].items);
        this.getData(true, true);
      } else {
        this.getData(true);
      }
    }
    this.$emit('viewChanged', this.viewType);
    // window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  get isPerformer(): boolean {
    return vxm.user.data.role === 'performer';
  }

  get isMy(): boolean {
    if (this.isPerformer) {
      return this.model._id ? this.model._id === vxm.user.data._id : true;
    }
    return false;
  }

  handleScroll() {
    // const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (!this.isRequestSend && this.totalMedia > this.media.length) {
      this.getData();
    }
  }

  @Watch('model')
  onModelChange(model: ModelDataInterface, oldModel: ModelDataInterface) {
    if (!this.isLogin) {
      this.params.take = 2;
    }

    if (model) {
      this.params.performerId = model._id;
      this.refreshMedia();
    }
  }

  @Watch('params.keyword')
  onSearchChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.debouncedSearch();
    }
  }

  get paymentSuccessCount() {
    return this.$store.state.paymentSuccessCount;
  }

  @Watch('paymentSuccessCount') onPaymentMessage(isFirst = false, backgroundUpdate = false) {
    const take = (this.params.page as number) * (this.params.take as number);
    this.isRequestSend = !backgroundUpdate;
    if (!this.isPerformer || this.isMy) {
      vxm.fan
        .searchPerformerMedia({...this.params, page: 1, take: take})
        .then((res) => {
          this.totalMedia = res.data.count;
          if (isFirst) {
            this.media = res.data.items;
            this.updLocalStorage(res.data);
          } else {
            this.media = res.data.items;
          }
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }

  switchView(viewType: string) {
    this.viewType = viewType;
    this.$emit('viewChanged', this.viewType);
  }

  // get random index to create blank space in grid
  randomInt(min: number, max: number) {
    return min + Math.floor((max - min) * Math.random());
  }

  getData(isFirst = false, backgroundUpdate = false) {
    if (!this.isLogin) {
      // vxm.fan
      //   .searchPerformerMediaForGuestUser(this.params)
      //   .then((res) => {
      //     this.totalMedia = res.data.count;
      //     if (isFirst) {
      //       this.media = res.data.items;
      //       this.updLocalStorage(res.data);
      //     } else {
      //       this.media.push(...res.data.items);
      //     }
      //     (this.params.page as number)++;
      //   })
      //   .catch((error) => {
      //     return error;
      //   })
      //   .then(() => {
      //     this.isRequestSend = false;
      //   });
      return;
    }

    if (!this.isPerformer || this.isMy) {
      this.isRequestSend = !backgroundUpdate;
      vxm.fan
        .searchPerformerMedia(this.params)
        .then((res) => {
          this.totalMedia = res.data.count;
          if (isFirst) {
            this.media = res.data.items;
            this.updLocalStorage(res.data);
          } else {
            this.media.push(...res.data.items);
          }
          (this.params.page as number)++;
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }

  updLocalStorage(data) {
    let storage = JSON.parse(localStorage.getItem('fan') as string);
    if (!storage) {
      storage = {};
      storage[this.$route.name as string] = {items: [], count: 0, performerId: ''};
    }
    storage[this.$route.name as string] = {
      performerId: this.params.performerId,
      items: data.items,
      count: data.count,
    };
    localStorage.setItem('fan', JSON.stringify(storage));
  }

  refreshMedia() {
    this.params.page = 1;
    this.media = [];
    const storage = JSON.parse(localStorage.getItem('fan') as string);
    if (
      storage &&
      storage[this.$route.name as string] &&
      this.params.performerId === storage[this.$route.name as string].performerId
    ) {
      this.totalMedia = storage[this.$route.name as string].count;
      this.media.push(...storage[this.$route.name as string].items);
      this.getData(true, true);
    } else {
      this.getData(true);
    }
  }

  changeSearch() {
    this.params.page = 1;
    this.getData(true);
  }

  changeSort() {
    this.params.page = 1;
    this.getData(true);
  }
}
