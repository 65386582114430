



















































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {ModelDataInterface} from '@/types/userDataInterface';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import {VideoInterface} from '@/types/videoInterface';
import NumbersMixin from '@/mixins/NumbersMixin';
import {vxm} from '@/store';
import LoginOrRegisterModal from '@/components/modals/LoginOrRegisterModal.vue';
import {MediaEnum} from '@/types/enums/MediaEnum';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import DebounceMixin from '@/mixins/DebounceMixin';
import PostCardNew from '@/components/PostCardNew.vue';

@Component({
  components: {
    LoginOrRegisterModal,
    PostCardNew,
  },
})
export default class VideoGrid extends Mixins(NumbersMixin, MomentDateMixin, DebounceMixin) {
  viewType = 'grid';
  mediaTypes = MediaEnum;
  showLoginModal = false;
  searchQuery = '';
  debouncedSearch = this.debounce(this.changeSearch, 700);
  currentSort = 'newest';
  sortOptions = [
    {
      value: 'newest',
      name: 'Newest',
    },
    {
      value: 'mostLike',
      name: 'Most Like',
    },
    {
      value: 'mostView',
      name: 'Most View',
    },
    {
      value: 'oldest',
      name: 'Oldest',
    },
  ];

  @Prop(String) readonly type!: string;
  @Prop(Array) readonly data!: Array<AffiliateContentResponse | VideoInterface>;
  @Prop(Object) readonly model!: ModelDataInterface;
  @Prop(Number) readonly total!: number;
  @Prop({default: false}) readonly isSubscribed!: boolean;
  @Prop({default: false}) readonly isPurchasedFilter!: boolean;
  @Prop({default: ''}) readonly performerRole!: string;

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  get isMyPage(): boolean {
    return this.model._id === vxm.user.data._id;
  }

  mounted() {
    this.$emit('viewChanged', this.viewType);
  }

  @Watch('searchQuery')
  onSearchQueryChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.debouncedSearch();
    }
  }

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  switchView(viewType: string) {
    this.viewType = viewType;
    this.$emit('viewChanged', this.viewType);
  }

  changeSearch() {
    this.$emit('changeSearch', this.searchQuery);
  }

  changeSort() {
    this.$emit('changeSort', this.currentSort);
  }
}
